
import { formatNumber } from '@angular/common';

import { FieldsService } from './../../_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from './../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, ElementRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { ComponentService } from 'src/app/_services/templates.service';
import { Router } from '@angular/router';
import { stringify } from '@angular/compiler/src/util';


@Component({
  selector: 'app-cms-products-and-services',
  templateUrl: './cms-products-and-services.component.html',
  styleUrls: ['./cms-products-and-services.component.scss'],
  providers: [FieldsService]
})


export class CmsProductsAndServicesComponent implements OnInit, AfterViewInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  @Input() rtaGiro: any;
  activeModal = false;
  esRegresar=false;
  cmsProductsServices: any =  [
    {
      titleSrc: '/uploads/images/recargas/condiciones-de-servicio.png',
      titleTitle: 'texto de item',
      titleAlt: 'titleAlt',
      sendUrlCalc: '#',
      sendUrlStatus: '#',
      Fields: [
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 1',
          linkIcon: null
        },
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 2',
          linkIcon: null
        },
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 3',
          linkIcon: null
        },
        {
          linkUrl: '#',
          linkText: 'Texto de enlace 4',
          linkIcon: null
        },
      ]
    }
  ];

  captchaValid = false;
  @ViewChild ('appFormCostTurn', {static: false}) formCostTurn: DynamicFormContainerComponent;
  @ViewChild ('appFormTurnStatus', {static: false}) formdTurnStatus: DynamicFormContainerComponent;
  @ViewChild('recaptcha', {static: false }) recaptchaElement: ElementRef;

  data: Array<{formCostTurn: any, formTurnStatus}> = [];

  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  }

  constructor(
    service: FieldsService,
    private apiService: ComponentService,
    private router:Router
    ){

    this.data['formCostTurn'] = service.getQuestions(service.getCostTurn());
    this.data['formTurnStatus']= service.getQuestions(service.formTurnStatus());
  }

  ngOnInit() {

    if(this.contenido != undefined && this.contenido.Contenido.length != 0) {
      this.cmsProductsServices = this.contenido.Contenido;
      this.addRecaptchaScript();
  }

  }
  ngAfterViewInit() {
    this.showDataCtGiro();

  }
  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      obj.renderReCaptcha()
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    if(this.recaptchaElement){
     window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        'sitekey' : '6LefLd0UAAAAAMKXEXDyCxitCvKT1LWkX01gC99E',
        'callback': (response) => {
          this.captchaValid = true;
        }
      });
    }
  }

  onSubmitCostTurns() {
    this.formCostTurn.onSubmit();
    const datos: any = this.formCostTurn.payLoad;
    if(this.formCostTurn.form.invalid){
      return false;
    }
    this.modal.show(
      'assets/images/icon-modal-send.png',
      'assets/images/title-modal-send.png',
      'Espere mientras consultamos la información'
    );
    this.apiService.getCalcularGiroNacional(datos.valorGiro).subscribe(
      (result: any) => {
        let total = result.tarifa.tarifaFija + result.tarifa.tarifaVariable;
        total -= result.tarifa.descuento;
        this.formCostTurn.form.controls.costo.setValue(
          formatNumber(total, 'en'),
          {emitEvent:false}
        );
        this.formCostTurn.form.controls.total.setValue(
          formatNumber(
            result.tarifa.valorTotalTransaccion, 'en'),
            {emitEvent:false}
          );
        this.modal.hidde();
      },
      err => {
        this.modal.show(
          'assets/images/icon-modal-error.png',
          'assets/images/title-modal-error.png',
          err.message
        );
      }
    );
  }

  onSubmitTurnStatus() {

    if(this.formdTurnStatus.form.invalid || !this.captchaValid){
      return false;
    }
    this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', 'Espere mientras consultamos la información');
    this.formdTurnStatus.onSubmit();
    const datos: any = this.formdTurnStatus.payLoad;

    if(datos.valor == '' || datos.digito == '' || !this.captchaValid){
      this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Debe ingresar un valor');
      return false;
    }

    localStorage.setItem('valor',datos.valor);
    localStorage.setItem('digito',datos.digito);
    localStorage.setItem('cboTipoDocumento',datos.cboTipoDocumento);
    localStorage.setItem('NumDocumento',datos.NumDocumento);
    this.modal.hidde();
    this.router.navigate(['Consulta-estado-giro'])
    
  }

  showDataCtGiro(){
      if(localStorage.getItem('esRegresar')){
        this.esRegresar= localStorage.getItem('esRegresar')== 'true'?true:false;
      }
      else{
        this.esRegresar=false;
      }

      if(this.esRegresar){

          //this.formdTurnStatus.onSubmit();

          if(localStorage.getItem('valor')){
            this.formdTurnStatus.form.controls.valor.setValue(localStorage.getItem('valor'), {emitEvent:false})
          }
          if(localStorage.getItem('digito')){
            this.formdTurnStatus.form.controls.digito.setValue(localStorage.getItem('digito'), {emitEvent:false})
          }
          if(localStorage.getItem('cboTipoDocumento')){
            this.formdTurnStatus.form.controls.cboTipoDocumento.setValue(localStorage.getItem('cboTipoDocumento'), {emitEvent:false})
          }
          if(localStorage.getItem('NumDocumento')){
            this.formdTurnStatus.form.controls.NumDocumento.setValue(localStorage.getItem('NumDocumento'), {emitEvent:false})
          }

          //localStorage.clear();
      }
}

  onChangeValues(evt){
    this.formCostTurn.form.controls.costo.setValue(formatNumber(0, 'en'), {emitEvent:false});
    this.formCostTurn.form.controls.total.setValue(formatNumber(0, 'en'), {emitEvent:false});
  }

}
