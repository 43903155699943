import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { Page } from 'src/app/_models/page';
import { TemplateServiceService } from 'src/app/_services/template-service.service';
import { ComponentBase } from 'src/app/_models/component-base';
import { Almacen } from 'src/app/_classes/almacen';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  chatbot: string = 'https://cdn.agentbot.net/core/80a730fe763ac82254b8154a70f43e4c.js';
  @Input() page: Page;
  @Input() api_url: any;
  footer =
    {
      Nombre: 'Footer',
      Ubicacion: '19',
      Contenido: [
        {
          imageUrl: 'uploads/images/footer.png',
          imageAlt:null,
          imageTitle:null,
          copyRight: {
            left: '',
            Fields: '',
            right: ''
          },
          copyRightUrl:  'uploads/images/footer.png',
          copyRightAlt:  'uploads/images/footer.png',
          copyRightTitle:  'uploads/images/footer.png',
          Fields: [
            {
              Propiedad: 'colType1',
              imageUrl: 'uploads/images/logo-efecty.svg',
              imageAlt: 'uploads/images/logo-efecty.svg',
              imageTitle: 'uploads/images/logo-efecty.svg',
              description: "<p>Línea de atención</p><p><strong>(1) null</strong></p><p>Lunes a Viernes</p><p>7:00 am - 5:00 pm</p><p class='mg-bottom-xs-15'><strong>Síguenos en:</strong></p>",
              Fields: [
                {
                  icon: "fa-facebook",
                  link: '/whatsapp'
                },
                {
                  icon: "fa-twitter",
                  link: '/facebook'
                },
                {
                  icon: "fa-instagram",
                  link: '/twiitter'
                },
                {
                  icon: "fa-youtube",
                  link: '/youtube'
                },
              ]
            },
            {
              Propiedad: 'colType2',
              title: 'Conoce Efecty',
              Fields: [
                  {
                    item: "<a href='./sobre-la-compania'>Sobre la compañía</a>"
                  },
                  {
                    item: "<a href='./gestion'>Sistemas integrados de gestión</a>"
                  },
                  {
                    item: "<a href='./sostenibilidad'>Sostenibilidad</a>"
                  },
                  {
                    item: "<a href='./trabaja-con-nosotros'>Trabaja con nosotros</a>"
                  },
                  {
                    item: "<a href='./promociones'>Promociones</a>"
                  },
                  {
                    item: "<a href='./blog'>Blog de noticias</a>"
                  }
                ]
            },
            {
              Propiedad: 'colType2',
              title: 'Conoce Efecty',
              Fields: [
                  {
                    item: "<a href='#'>Portal empresarial</a>"
                  },
                  {
                    item: "<a href='#'>Portal canal corporativo</a>"
                  },
                  {
                    item: "<a href='#'>Portal colaboradores</a>"
                  },
                  {
                    item: "<a href='/portales-internos'>Portales internos</a>"
                  }
                ]
            },
            {
              Propiedad: 'colType2',
              title: 'Conoce Efecty',
              Fields: [
                {
                  item: "<a href='/atencion-al-cliente'>Atención al cliente</a>"
                },
                {
                  item: "<a href='/telefono-de-contacto'>Teléfonos de contacto por regional</a>"
                },
                {
                  item: "<a href='/radicar-pqr'>Radicar PQR</a>"
                },
                {
                  item: "<a href='/consultar-pqr'>Consultar PQR</a>"
                },
                {
                  item: "<a href='/normatividad'>Normatividad</a>"
                }
              ]
            }
          ]
        }
      ]
    }

  constructor(
    private templateService: TemplateServiceService,
    private almacen: Almacen,
    private router: Router

  ) {
  }
    ngOnInit() {
        this.loadTemplate();
        try {
            const urlTree = this.router.parseUrl(this.router.url);
            if (urlTree.root.children.primary.segments[1].path == "true") {
                this.templateService.showGyra = !this.templateService.showGyra;
            } else {
                this.loadScript(this.chatbot);
            }
        } catch (error) {
            this.loadScript(this.chatbot);
        }

    }

    loadScript(url: string) {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
    }

  loadTemplate(){
    this.almacen.currentBase.subscribe(
        (data: any) => {
          if(data){
            this.footer.Contenido = data.Footer;
          }

        },
        error => {
        }
      )
  }
}
