import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';

@Component({
    selector: 'app-form-punto-pqr-radicar',
    templateUrl: './form-punto-pqr-radicar.component.html',
    styleUrls: ['./form-punto-pqr-radicar.component.scss']
})
export class FormPuntoPqrRadicarComponent implements OnInit {
    formSubmitPqr = {
        Nombre: 'Radicar PQR',
        Ubicacion: '27',
        Contenido: [
            {
                Propiedad: 'form',
                Contenido: {
                    action: '/',
                    Contenido: [
                        {
                            Propiedad: 'row1',
                            Contenido: [
                                {
                                    Propiedad: 'input1',
                                    type: 'text',
                                    label: 'Código punto de atención:*',
                                    placeholder: 'Buscar punto de servicio',
                                    name: 'CodigoPuntoAtencion',
                                },
                                {
                                    Propiedad: 'input2',
                                    type: 'text',
                                    label: 'Correo electrónico punto de atención al público:*',
                                    name: 'CorreoElectronico',
                                },
                            ]
                        },
                        {
                            Propiedad: 'row2',
                            Contenido: [
                                {
                                    Propiedad: 'select',
                                    label: 'Tipo de Documento solicitante:*',
                                    name: 'TipoDocumentoGUID',
                                    Contenido: [
                                        {
                                            option: 'Cédula de Ciudadanía',
                                            value: 'c7cdde6f-e6f4-e811-a96b-000d3a',
                                            codigo: 'CC'
                                        },
                                        {
                                            option: 'Cedula de Extranjería',
                                            value: 'c9cdde6f-e6f4-e811-a96b-000d3a1d715f',
                                            codigo: 'CE'
                                        },
                                        {
                                            option: 'Pasaporte',
                                            value: 'cdcdde6f-e6f4-e811-a96b-000d3a1d715f',
                                            codigo: 'PP'
                                        },
                                    ]
                                },
                                {
                                    Propiedad: 'input3',
                                    type: 'text',
                                    label: 'Correo electrónico punto de atención al público:*',
                                    name: 'CorreoElectronico',
                                },
                            ]
                        },
                        {
                            Propiedad: 'row3',
                            Contenido: [

                            ]
                        },
                        {
                            Propiedad: 'row4',
                            Contenido: [
                                {
                                    Propiedad: 'input6',
                                    type: 'text',
                                    label: 'Número Celular Propietario o administrador PAP:*',
                                    name: 'TelefonoCelular',
                                },
                                {
                                    Propiedad: 'input7',
                                    type: 'text',
                                    label: 'Número fijo del propietario o administrador PAP:',
                                    name: 'TelefonoFijo',
                                },
                            ]
                        },
                        {
                            Propiedad: 'tabs',
                            Contenido: [
                                {
                                    Propiedad: 'col1',
                                    Contenido: {
                                        title: 'Tipo de solicitud: *'
                                    }
                                },
                                {
                                    Propiedad: 'col2',
                                    name: 'TipoSolicitudGUID',
                                    Contenido: [
                                        {
                                            label: 'Petición'
                                        },
                                        {
                                            label: 'Queja'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            Propiedad: 'fields',
                            Contenido: [

                                {
                                    Propiedad: 'autocomplete',
                                    type: 'text',
                                    label: 'Código Punto de Atención:*',
                                    placeholder: 'Buscar punto de servicio',
                                    name: 'CodigoPuntoAtencion',
                                },
                                {
                                    Propiedad: 'input',
                                    type: 'text',
                                    label: 'Correo electrónico punto de atención al público:*',
                                    name: 'CorreoElectronico'
                                },
                                {
                                    Propiedad: 'tipodocumento',
                                    label: 'Tipo de Documento solicitante:*',
                                    name: 'TipoDocumentoGUID',
                                    Contenido: [
                                        {
                                            option: 'Cedula'
                                        },
                                        {
                                            option: 'Otro'
                                        }
                                    ]
                                },
                                {
                                    Propiedad: 'busqueda',
                                    type: 'number',
                                    label: 'Número de documento solicitante:*',
                                    name: 'NumeroDocumento',
                                },
                                {
                                    Propiedad: 'input',
                                    type: 'text',
                                    label: 'Nombre(s) Solicitante:*',
                                    name: 'Nombres',
                                },
                                {
                                    Propiedad: 'input',
                                    type: 'text',
                                    label: 'Apellido(S) Solicitante:*',
                                    name: 'Apellidos',
                                },
                                {
                                    Propiedad: 'input',
                                    type: 'number',
                                    label: 'Número Celular Propietario o administrador PAP:',
                                    name: 'TelefonoCelular',
                                },
                                {
                                    Propiedad: 'input',
                                    type: 'number',
                                    label: 'Número fijo del propietario o administrador PAP::',
                                    name: 'TelefonoFijo',
                                },
                                {
                                    Propiedad: 'radio',
                                    name: 'TipoSolicitudGUID',
                                    type: 'radio',
                                    label: 'Tipo de solicitud:*',
                                    Contenido: [
                                        {
                                            GUID: '7fd71174-9c1d-e911-a837-000d3a5377e1',
                                            Codigo: 'TS05',
                                            Nombre: 'Petición'
                                        },
                                        {
                                            GUID: '77d71174-9c1d-e911-a837-000d3a5377e1',
                                            Codigo: 'TS01',
                                            Nombre: 'Queja'
                                        }
                                    ]
                                },
                                {
                                    Propiedad: 'motivo',
                                    name: 'MotivoGUID',
                                    type: 'radio',
                                    label: 'Motivo de solicitud:*',
                                    Contenido: [

                                    ]
                                },
                                {
                                    Propiedad: 'textarea',
                                    name: 'Descripcion',
                                    label: 'Descripción PQR:*',
                                    labelDescription: 'En este campo describe de manera específica:¿ Cuándo sucedió? fecha (día-mes-año)¿Qué sucedió? Ej. En el momento de efectuar recaudo, este fue aplicado al proyecto errado xxxxx, bajo la orden de servicio errada xxxxx, por lo que fue generada la orden de servicio correcta xxxxx. ¿Cual es tu solicitud? Ej. Devolución orden xxxxx'
                                },
                                {
                                    Propiedad: 'file',
                                    type: 'file',
                                    name: 'UploadFile',
                                    icon: API_URI + 'uploads/images/icon-upload-files.png',
                                    label: 'Adjuntar archivos',
                                    copyright: 'Recuerde que, si la solicitud es a nombre propio debe adjuntar copia de su cédula legible por ambas caras, si es a nombre de una empresa debe adjuntar Cámara de Comercio vigente, si es un tercero debe adjuntar poder vigente autenticado por notaria',
                                    button: 'seleccionar archivo'
                                }
                            ]
                        }
                    ],
                    terminos: 'Autoriza tratamiento de datos personales',
                    policy: 'Conoce la política de datos aquí',
                    button: 'Radicar',

                }
            }
        ]
    };

    pageForm: FormGroup;

    modal = {
        image: 'assets/images/icon-modal-send.png',
        title: 'assets/images/title-modal-send.png',
        text: 'El mensaje',
        codigopqr: '',
        despqr: 'mensaje',
        active: false,
        hidde() {
            this.active = false;
        },
        show(image, title, message, codigopqr, despqr) {
            this.image = image;
            this.title = title;
            this.text = message;
            this.codigopqr = codigopqr;
            this.despqr = despqr;
            this.active = true;
        }
    }

    loading = {
        image: 'assets/images/loading.gif',
        active: false,
        hidde() {
            this.active = false;
        },
        show(image) {
            this.image = image;
            this.active = true;
        }
    };

    tamanioArchivo;
    extensions;
    tipos;
    documentos;
    motivos = [];
    subtipos = []
    municipios;
    municipioBuscado;
    imageSrc;
    files;
    puntos;
    showAutocomplete = false;


    constructor(
        private formBuilder: FormBuilder,
        private apiService: ComponentService,
    ) {




    }
    ngOnInit() {
        this.pageForm = this.formBuilder.group(
            {
                TipoSolicitudGUID: ['', Validators.required],
                Descripcion: ['', Validators.required],
                PuntoAtencionGUID: ['', Validators.required],
                CodigoPuntoAtencion: ['', Validators.required],
                TipoDocumentoGUID: ['', Validators.required],
                MotivoGUID: ['', Validators.required],
                NumeroDocumento: ['', [Validators.required, Validators.maxLength(15)]],
                Nombres: [{ value: '', disabled: true }, Validators.required],
                Apellidos: [{ value: '', disabled: true }, Validators.required],
                TelefonoCelular: [{ value: '', disabled: true }, Validators.required],
                TelefonoFijo: [{ value: '', disabled: true }, Validators.required],
                Direccion: ['', Validators.required],
                MunicipioGUID: ['', Validators.required],
                CorreoElectronico: [{ value: '', disabled: true }, Validators.required],
                MedioRespuesta: ['3'],
                AutorizaTratamientoDatosPersonales: ['', Validators.required],
                captcha: [null, [Validators.required]]
            }
        );
        this.pageForm.valueChanges.subscribe(x => this.valuesChange(x));
        this.loadData();
    }

    async loadData() {
        await this.apiService.getJSON();
        this.apiService.getTipoDocumento().subscribe(
            response => {
                this.documentos = response;
            },
            err => {
            }
        );
    }

    valuesChange(values) {
        // Si el tipo de solicitud es peticion
        switch (values.TipoSolicitudGUID) {

            case '7fd71174-9c1d-e911-a837-000d3a5377e1':
                this.motivos = [
                    {
                        "GUID": "d5129427-9cfa-e911-a869-000d3a537884",
                        "Codigo": "MS014",
                        "Nombre": "Solicitud de Devolucion de Recaudo"
                    }
                ];
                break;
            case '77d71174-9c1d-e911-a837-000d3a5377e1':
                this.motivos = [
                    {
                        "GUID": "05129427-9cfa-e911-a869-000d3a537884",
                        "Codigo": "MS240",
                        "Nombre": "Inconformidad descuento PQR"
                    }
                ];
                break;
            default:
                this.motivos = [];
                break;
        }
    }

    async submit() {
        this.loading.show('assets/images/loading.gif');

        if (this.pageForm.invalid) {
            this.modal.show(
                'assets/images/icon-modal-error.png',
                'assets/images/title-modal-error.png',
                '',
                '',
                'Todos los campos del formulario son requeridos,' + this.getFormValidationErrors());
            this.loading.hidde();
            return false;
        }


        let toSend = this.pageForm.value;
        toSend.Contacto = {
            TipoDocumentoGUID: toSend.TipoDocumentoGUID,
            NumeroDocumento: toSend.NumeroDocumento,
        }
        toSend.NotaAdjunta = this.files;
        await this.apiService.getJSON();
        this.apiService.postCrearPQRPunto(toSend).subscribe(
            (response: any) => {
                this.modal.show(
                    'assets/images/icon-modal-send.png',
                    'assets/images/title-modal-atencion.png',
                    '<span class="mensaje">Con el fin de tramitar tu solicitud te informamos que será gestionada con el siguiente número de caso: </span>',
                    response.NumeroRadicado,
                    '<strong>Recuerda tener presente el número de caso.</strong><br><span>Gracias por contactarnos, esperamos darte respuesta lo más pronto posible</span><br><br>');
                this.loading.hidde();
                this.resetForm();
            },
            err => {

                if (err.error.Message !== '' && err.error.Message !== null && err.error.Message !== undefined) {
                    this.modal.show(
                        'assets/images/icon-modal-error.png',
                        'assets/images/title-modal-error.png',
                        '',
                        '',
                        err.error.Message,
                    );
                } else {
                    this.modal.show(
                        'assets/images/icon-modal-error.png',
                        'assets/images/title-modal-error.png',
                        '',
                        '',
                        err.message,
                    );
                }
                this.loading.hidde();
            }
        );
    }

    resolved(captchaResponse: string) {
        this.pageForm.controls.captcha.setValue(captchaResponse);
    }

    getFormValidationErrors() {
        let textErrors = '';
        Object.keys(this.pageForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.pageForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    textErrors += '<br> ' + key + ' ' + keyError + '  ', controlErrors[keyError];
                });
            }
        });
        return textErrors;
    }

    seleccionarPunto(item) {
        this.pageForm.controls.CorreoElectronico.setValue(item.Correo);
        this.pageForm.controls.TelefonoFijo.setValue(item.TelefonoFijo);
        this.pageForm.controls.TelefonoCelular.setValue(item.Celular);
        this.pageForm.controls.MunicipioGUID.setValue(item.Municipio.GUID);
        this.pageForm.controls.Direccion.setValue(item.Direccion);
        this.pageForm.controls.PuntoAtencionGUID.setValue(item.GUID);
        this.pageForm.controls.CodigoPuntoAtencion.setValue(item.Codigo);
        this.showAutocomplete = false;
    }

    buscarAgente($even) {

    }

    cambiandoCiudad(item) {
    }

    set municipio(municipio) {
        this.municipioBuscado = municipio;
        this.pageForm.controls.MunicipioGUID.setValue(municipio);
        this.apiService.getMunicipios(this.municipioBuscado).subscribe(
            response => {
                this.municipios = response;
            },
            err => {

            }
        );
    }

    get municipio() {
        return this.municipioBuscado;
    }

    handleFileInput(fileInput) {
        let fileData = [];
        fileData = fileInput.target.files;
        for (let i = 0; i < fileData.length; i++) {
            this.uploadDocument(fileData[i]);
        }
    }

    passFile(fileData) {
        for (let i = 0; i < fileData.length; i++) {
            this.uploadDocument(fileData[i]);
        }
    }

    uploadDocument(file) {
        let fileReader = new FileReader();
        fileReader.onload = (readerEvt) => {
            const objetivo: any = readerEvt.target;
            const contentFile = btoa(objetivo.result.toString());
            this.addFile(contentFile, file);
        }
        fileReader.readAsBinaryString(file);
    }

    addFile(contenFile, file) {
        file = {
            Texto: file.name,
            ContenidoArchivo: contenFile,
            NombreArchivo: file.name,
            MimeType: file.type,
            TamanioArchivo: file.size,
            FechaCreacion: null
        }

        this.files = file;
    }

    delFile() {
        this.files = null;
    }

    buscarPunto(evento) {
        this.apiService.getPunto(evento.target.value).subscribe(
            (response: any) => {
                this.puntos = response;
                if (this.puntos.length > 0) {
                    this.showAutocomplete = true;
                } else {
                    this.showAutocomplete = false;
                }
            },
            err => {
                this.showAutocomplete = false;
            }
        );
    }

    buscarEspecialista(evento) {

        let toSend = this.pageForm.value;

        this.apiService.getEspecialista(toSend.TipoDocumentoGUID, toSend.NumeroDocumento).subscribe(
            (response: any) => {
                this.pageForm.controls.Nombres.setValue(response.USUnombre);
                this.pageForm.controls.Apellidos.setValue(response.USUapellidos);
            },
            err => {
                this.modal.show(
                    '/assets/images/icon-modal-error.png',
                    '',
                    '',
                    '',
                    'No encontramos un especialista con los datos suministrados');
            }
        );
    }

    resetForm() {
        // this.pageForm.controls.TipoSolicitudGUID.value('');
        this.pageForm.controls.Descripcion.value('');
        this.pageForm.controls.PuntoAtencionGUID.value('');
        this.pageForm.controls.CodigoPuntoAtencion.value('');
        // this.pageForm.controls.TipoDocumentoGUID.value('');
        // this.pageForm.controls.MotivoGUID.value('');
        this.pageForm.controls.NumeroDocumento.value('');
        this.pageForm.controls.Nombres.value('');
        this.pageForm.controls.Apellidos.value('');
        this.pageForm.controls.TelefonoCelular.value('');
        this.pageForm.controls.TelefonoFijo.value('');
        this.pageForm.controls.Direccion.value('');
        this.pageForm.controls.MunicipioGUID.value('');
        this.pageForm.controls.CorreoElectronico.value('');
        // this.pageForm.controls.MedioRespuesta.value('');
        // this.pageForm.controls.AutorizaTratamientoDatosPersonales.value('');
    }

}
