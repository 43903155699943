import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, NgForm } from '@angular/forms';
import { Page } from 'src/app/_models/page';
import { TemplateServiceService } from 'src/app/_services/template-service.service';
import { ComponentBase } from 'src/app/_models/component-base';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Almacen } from 'src/app/_classes/almacen';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() page: Page;
  @Input() api_url: any;

  searchTerms = '';

  navbar = {
    Nombre: 'navbar',
    Ubicacion: 'navbar',
    Contenido: [
      {
        imageUrl: 'uploads/images/logo-efecty.svg',
        imageAlt: null,
        imageTitle: null,
        ingresarUrl : 'home',
        Fields: []
      }
    ]
  }


  public toggleSearch = false;
  public toggleMenu = false;
  public statusMenuToggle: boolean;
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private templateService: TemplateServiceService,
    private route: Router,
    private almacen: Almacen,

  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      query: new FormControl('')
    });
    this.loadTemplate();
   
  }
  
  loadTemplate(){
    this.almacen.currentBase.subscribe(
      (data: any) => {
        if(data){
          this.navbar.Contenido = data.Navbar;
        }
      },
      error => {
      }
    )
  }

  setDataComponents(){
    this.page.configurations = {};
    this.page.components.forEach((component, i) =>{
      this.page.configurations[component.Nombre] = ComponentBase.setPropiedades(component.Contenido)
    });
    this.navbar.Contenido = this.page.configurations.Navbar;
  }


  public onToggleSearch() {
    if(this.toggleSearch && this.searchTerms.length > 0){
      this.route.navigate(['busqueda', this.searchTerms]);
    }
    this.toggleSearch = !this.toggleSearch;
  }

  onToggleSubMenu(item, lista) {
    var myVideo: any = document.getElementsByName("video");
    for(let i of myVideo){
      i.play();
    }
    lista.forEach(menu => {
      if (menu == item){
        item.isVisible = item.isVisible == undefined ? true : !item.isVisible;
      }else{
        menu.isVisible = false;
        this.hiddeItems(menu);
      }
    });

  }

  public onToggleMenu() {
    this.toggleMenu = !this.toggleMenu;
    if (!this.toggleMenu) {
      this.navbar.Contenido[0].Fields.forEach(item => this.hiddeItems(item));
    }
  }

  hiddenMenu() {
    this.toggleMenu = false;
    if (!this.toggleMenu) {
      this.navbar.Contenido[0].Fields.forEach(item => this.hiddeItems(item));
    }
  }
  hiddenSubMenu(fields){
    fields.forEach(item => {
      if (item.Fields || item.image) {
      this.hiddeItems(item)
      }
    });
  }

  hiddeItems(item) {
    item.isVisible = false;
    if (item.Fields) {
      item.Fields.forEach(subitem => this.hiddeItems(subitem));
    }
  }

  onSubmit(f: NgForm){
    this.route.navigate(['busqueda', this.searchTerms]);
  }

  isSubmenu(item): boolean{
    if (item.Fields || item.image) {
      if ((item.Fields && item.Fields.length > 0) || item.image) {
        return true;
      }
    }
    return false;
  }
}
