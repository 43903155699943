import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: 'app-validator-main',
    templateUrl: './validator-main.component.html',
    styleUrls: ['./validator-main.component.scss']
})
export class ValidatorMainComponent implements OnInit {

    @Input() contenido: ComponentBase<any>;
    @Input() api_url: any;
    activeModal = true;

  constructor(private _sanitizer: DomSanitizer,) { }

    data: any = [
        {
            tituloUno: 'uploads/images/atencion-al-cliente-titulo.png',
            tituloDos: 'uploads/images/atencion-al-cliente-titulo.png',
            tituloImageAlt: 'uploads/images/atencion-al-cliente-titulo.png',
            tituloImageTitle: 'uploads/images/atencion-al-cliente-titulo.png',
            description: '<b>Entre más giros</b> y pagos realices, más monedas acumularas para redimirlas...',
            position: '0'
        }
    ];

    secureUrl(code) {
        return this._sanitizer.bypassSecurityTrustResourceUrl(code);
    }

    ngOnInit() {
        if(this.contenido != undefined && this.contenido.Contenido.length != 0){
            this.data = this.contenido.Contenido;
        }
    }
}
