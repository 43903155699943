import { FieldsService } from './../../_helpers/dynamic_form/service/fields.service';
import { Component, OnInit, Input } from '@angular/core';
import { ComponentService } from 'src/app/_services/templates.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cms-products-and-services-comp',
  templateUrl: './cms-products-and-services-comp.component.html',
  styleUrls: ['./cms-products-and-services-comp.component.scss'],
  providers: [FieldsService]
})
export class CmsProductsAndServicesCompComponent implements OnInit {


  @Input() api_url:any;
  @Input() items:any;
  activeModal = false;
  isConsulta:boolean=false;
  esGiro:boolean=false;
  esPago:boolean=false;
  mensaje:string="El número del giro no existe, por favor digítelo nuevamente.";
  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  }
  valor:string;
  digito:string;
  cboTipoDocumento:string;
  NumDocumento:string;
  loading = 
  {
    image: 'assets/images/loading.gif',
    active: false,
    hidde() {
        this.active = false;
    },
    show(image) {
        this.image = image;
        this.active = true;
    }    
  };
  rtaNumGiro:string;
  rtaFechaCrea:string;
  rtaEstado:Estados;
  rtaPSDestino:string;
  rtaDirecDestino:string;
  rtaMunicipio:string;

  constructor(service: FieldsService, private apiService: ComponentService,private router:Router)
    {
     this.isConsulta=false;
      
  }
  ngOnInit() {
    this.consultarGiro();
  }

  consultarGiro(){
    window.scroll(0,0);
    this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', 'Consultando Giro');
    if(localStorage.getItem('valor')){
      this.valor=localStorage.getItem('valor')
     }
     if(localStorage.getItem('digito')){
      this.digito=localStorage.getItem('digito')
     }
     if(localStorage.getItem('cboTipoDocumento')){
      this.cboTipoDocumento=localStorage.getItem('cboTipoDocumento')
     }
     if(localStorage.getItem('NumDocumento')){
      this.NumDocumento=localStorage.getItem('NumDocumento')
     }
     if(this.valor!= undefined){
    this.apiService.getValidarGiroNacional(this.valor, this.digito, this.cboTipoDocumento,this.NumDocumento).subscribe(
      (result: any) => {
        if(result){
          this.isConsulta=result.Estado;
          if(result.Estado){
            this.mostrarDatosGiro(result)
            this.modal.hidde();
          }else{
            if(result.Mensaje.length==0){
              this.modal.hidde();
              this.mensaje=result.Mensaje;
            }
            else{
            this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png',  result.Mensaje);
            this.mensaje= result.Mensaje;
            }
            this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png',  result.Mensaje);
          }
        }else{
          this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'El número del giro no existe, por favor digítelo nuevamente.');
        }
      },
      err => {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'El número del giro no existe, por favor digítelo nuevamente.');
      });
    }
    else{
      this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'El número del giro no existe, por favor digítelo nuevamente.');
    }

    
  }

  mostrarDatosGiro(result: any){
    if(result.Giro.Num_Documento!=null){
      this.rtaNumGiro=result.Giro.Num_Documento;
    }else{
      this.rtaNumGiro=result.Pago.Num_Documento;
    }

    if(result.Giro.Fecha_Crea !=null){
      this.rtaFechaCrea=result.Giro.Fecha_Crea;
    }else{
      this.rtaFechaCrea=result.Pago.Fecha_Crea;
    }

    if(result.Pago.Estado != null){
      this.rtaEstado=this.devolverEstado(result.Pago.Estado);
    }else{
      this.rtaEstado=this.devolverEstado(result.Giro.Estado);
    }

    if(result.Pago.Descripcion_PS_Destino != null){
      this.rtaPSDestino=result.Pago.Descripcion_PS_Destino;
    }
    else{
      this.rtaPSDestino=result.Giro.Descripcion_PS_Destino;
    }

    if(result.Pago.Direccion_PS_Destino !=null){
      this.rtaDirecDestino=result.Pago.Direccion_PS_Destino;
    }else{
      this.rtaDirecDestino=result.Giro.Direccion_PS_Destino;
    }

    if(result.Pago.Municipio_Dest !=null){
      this.rtaMunicipio	=result.Pago.Municipio_Dest ;
    }
    else{
      this.rtaMunicipio	=result.Giro.Municipio_Dest ;
    }
    
  }
  devolverEstado(estado:string){
    if(estado== "ACT"){
      return Estados.ACT;
    }
    if(estado== "PAG"){
      return Estados.PAG;
    }

  }

  descargarAdmision(){
    if(localStorage.getItem('valor')){
      this.valor=localStorage.getItem('valor')
     }
     
     if(this.valor!= undefined)
     {
      this.apiService.getTirilla(this.valor,1).subscribe(
      (result: any) => {
        if(result!=null){
         this.downloadFile(result, `Giro ${this.valor}`)

        }else{
         this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'El pago no ha sido entregado.');
        }
      },
      err => {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Se genero un error al descargar la prueba de admision por favor intente mas tarde');
      }
      );
     }
  }

  descargarEntrega(){
    if(localStorage.getItem('valor')){
      this.valor=localStorage.getItem('valor')
     }

     if(this.valor!= undefined)
     {
      this.apiService.getTirilla(this.valor,4).subscribe(
      (result: any) => {
        if(result!=null){
         this.downloadFile(result, `Pago ${this.valor}`) 
        }
        else{
         this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'El giro no ha sido entregado.');
        }
      },
      err => {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Se genero un error al descargar la prueba de entrega por favor intente mas tarde');
      }
      );
     }
  }
 
  downloadFile(result:any, nombreArchivo:string) {

    var blobR = this.convertBase64ToBlobData(result, 'application/pdf', result.length);
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blobR,nombreArchivo);
    }
    else {
        var a = window.document.createElement("a");
        let blob = new Blob([blobR], { type: 'application/pdf' });
        a.href = URL.createObjectURL(blob);
        a.download =nombreArchivo; 
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

  }

  onSubmitTurnStatus() {
    localStorage.clear();
    this.router.navigate(['./'])
   
  }

  onSubmitReturn(){
    localStorage.setItem('esRegresar','true');
    this.router.navigate(['./'])
  }

  convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) 
  {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
  }

}  
enum Estados{
     ACT = 'Activo',
     PAG = 'Pagado'
    
   }
