import { filter } from 'rxjs/operators';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';
import { TemplateServiceService } from 'src/app/_services/template-service.service';
import { Router } from '@angular/router';
import { ElementFinder } from 'protractor';

@Component({
    selector: 'app-pqr',
    templateUrl: './pqr.component.html',
    styleUrls: ['./pqr.component.scss']
})
export class PqrComponent implements OnInit {

    pqr = {
        Nombre: 'Consultar PQR',
        Ubicacion: '26',
        Contenido: [
            {
                Propiedad: 'header',
                Contenido: {
                    icon: API_URI + 'uploads/images/Grupo 2017.png',
                    title: 'Consultar PQR',
                    description: 'Para realizar la consulta es importante que tengas disponible el número de radicación que se informó en el momento de realizar tu solicitud.'
                }
            },
            {
                Propiedad: 'form',
                Contenido: [
                    {
                        Propiedad: 'form1',
                        titleform: 'Por favor selecciona el tipo de documento y registra el número de documento',
                        Contenido: [
                            {
                                Propiedad: 'row1',
                                Contenido: [
                                    {
                                        Propiedad: 'select',
                                        name: 'tipodocumento',
                                        Contenido: [
                                            {
                                                option: 'Cedula'
                                            },
                                            {
                                                option: 'Otro'
                                            }
                                        ]
                                    },
                                    {
                                        type: 'number',
                                        Propiedad: 'input1',
                                        name: 'numerodocumento',
                                        label: '',
                                        placeholder: 'Escribe tu número sin puntos ni comas'
                                    }
                                ]
                            },
                            {
                                Propiedad: 'row2',
                                Contenido: [
                                    {
                                        Propiedad: 'input2',
                                        name: 'numerocaso',
                                        type: 'text',
                                        label: 'Número de caso:',
                                        placeholder: 'Escribe tu número sin puntos ni comas'
                                    }
                                ]
                            },
                            {
                                Propiedad: 'row2',
                                Contenido: [
                                    {
                                        Propiedad: 'Captcha',
                                        name: 'captcha',
                                    }
                                ]
                            },
                            {
                                Propiedad: 'row2',
                                Contenido: [
                                    {
                                        Propiedad: 'button'
                                    }
                                ]
                            }
                           
                        ]
                    }
                ]
            },
            {
                Propiedad: 'table',
                Contenido: [
                    {
                        title: 'Número de radicado',
                        description: ''
                    },
                    {
                        title: 'Número de CUN',
                        description: ''
                    },
                    {
                        title: 'Nombre del cliente',
                        description: ''
                    },
                    {
                        title: 'Número de documento',
                        description: ''
                    },
                    {
                        title: 'Tipo de solicitud MINTIC',
                        description: ''
                    },
                    {
                        title: 'Tipo de solicitud',
                        description: ''
                    },
                    {
                        title: 'Fecha de creación',
                        description: ''
                    },
                    {
                        title: 'Fecha estimada de solución',
                        description: ''
                    },
                    {
                        title: 'Estado de tramite',
                        description: ''
                    },
                    {
                        title: 'Estado de tramite SIC',
                        description: ''
                    },
                    {
                        title: 'Fecha de respuesta',
                        description: ''
                    },
                    {
                        title: 'Descarga respuesta',
                        description: ''
                    },
                ]
            }
        ]
    };

    modal = {
        image: 'assets/images/icon-modal-send.png',
        title: 'assets/images/title-modal-send.png',
        text: 'El mensaje',
        active: false,
        hidde() {
            this.active = false;
        },
        show(image, title, message) {
            this.image = image;
            this.title = title;
            this.text = message;
            this.active = true;
        }
    };
    banner = {
        image: 'assets/images/banner-resarcimiento.png',
        title: 'términos y condiciones',
        alt: 'condiciones',
        linkImg:'https://www.efecty.com.co/WebApi/uploads/TERMINOS_Y_CONDICIONES_PROGRAMA_DE_RESARCIMIENTO_DE_CLIENTES.pdf',
        active: false,
        hidde() {
            this.active = false;
        },
        show() {
            this.active = true;
        }
    };
    loading = {
        image: 'assets/images/loading.gif',
        active: false,
        hidde() {
            this.active = false;
        },
        show(image) {
            this.image = image;
            this.active = true;
        }
    };

    dataTable = [];

    formDocument: FormGroup;
    formNumber: FormGroup;
    listado: any;
    documentos;


    constructor(
        private formBuilder: FormBuilder,
        private apiService: ComponentService,
        private templateService: TemplateServiceService,
        private router: Router

    ) {
        this.loadData();
        
    }

    async loadData() {
        await this.apiService.getJSON();
        this.apiService.getTipoDocumento().subscribe(
            response => {
                this.documentos = response;
            },
            err => {
            }
        );
    }

    ngOnInit() {
        this.formDocument = this.formBuilder.group({
            select: 'Seleccione una opción...',
            tipodocumento: [null, []],
            numerodocumento: [null, [Validators.required,Validators.minLength(6),Validators.maxLength(15)]],
            numerocaso: [null, [Validators.required, Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\-\.\s]*$/)]],
            captcha:[null,[Validators.required]]
        });
        const urlTree = this.router.parseUrl(this.router.url);
        if (urlTree.root.children.primary.segments.length == 2) {
            this.templateService.hiddenComponents = !this.templateService.hiddenComponents;
        }
        this.banner.show();
    }

    async submitDocument() {
        this.loading.show('assets/images/loading.gif');
        if (this.formDocument.invalid) {
            this.dataTable = [];
            this.modal.show('assets/images/icon-modal-error.png',
                'assets/images/title-modal-error.png',
                'Debes completar los datos requeridos');
            this.loading.hidde();

            return;
        }
        const datos = this.formDocument.value;
        await this.apiService.getJSON();
        this.apiService.postConsultaPQRNatural(datos.tipodocumento, datos.numerodocumento, datos.numerocaso).subscribe(
            (result: any) => {
                var doc =this.documentos.filter(word => word.GUID==datos.tipodocumento);
 
                if (result.length == 0) {
                    this.dataTable = [];
                    this.modal.show(
                        'assets/images/icon-modal-error.png',
                        'assets/images/title-modal-error.png', 'No se encontraron resultados');
                }
                
                else if(result.filter(word => word.Contacto.NumeroDocumento==datos.numerodocumento
                    && (doc.length==0 || word.Contacto.TipoDocumento==doc[0].Codigo)).length==0)
                {
                    this.dataTable = [];
                    this.modal.show(
                        'assets/images/icon-modal-error.png',
                        'assets/images/title-modal-error.png', 'Ups Verifica la información registrada');
                }
                else{
                    this.dataTable = result;
                }
                this.loading.hidde();

            },
            err => {
                this.loading.hidde();
                this.modal.show(
                    'assets/images/icon-modal-error.png',
                    'assets/images/title-modal-error.png', err.message);
            }

        );
    }
    resolved(captchaResponse:string){
        this.formDocument.controls.captcha.setValue(captchaResponse);
    }
    convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    async descargar(item) {
        await this.apiService.getJSON();
        this.apiService.postConsultaPQRArchivo(item.GUID, item.NumeroRadicado).subscribe(
            (result: any) => {
                var blobR = this.convertBase64ToBlobData(result.ContenidoArchivo, result.MimeType, result.ContenidoArchivo.length);
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blobR, result.NombreArchivo);
                }
                else {
                    var a = window.document.createElement("a");
                    let blob = new Blob([blobR], { type: result.MimeType });
                    a.href = URL.createObjectURL(blob);
                    a.download = result.NombreArchivo;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            },
            err => {
                this.modal.show('assets/images/icon-modal-error.png',
                    'assets/images/title-modal-error.png',
                    'Su solictud aún no tiene documentos disponibles para descargar');
                this.loading.hidde();
            }
        );
    }

    getFormValidationErrors(form) {
        let textErrors = '';
        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    textErrors += ' ' + key + ', '  + ', ', controlErrors[keyError];
                });
            }
        });
        return textErrors;
    }
}
