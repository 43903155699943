import { FieldsService } from './../../_helpers/dynamic_form/service/fields.service';
import { DynamicFormContainerComponent } from './../../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { ComponentBase } from 'src/app/_models/component-base';
import { ComponentService } from '../../_services/templates.service';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'app-check-gyres',
  templateUrl: './check-gyres.component.html',
  styleUrls: ['./check-gyres.component.scss']
})
export class CheckGyresComponent implements OnInit {

  @Input() contenido: ComponentBase<any>;
  @Input() api_url:any;
  activeModal = true;

  checkGyres: any = {
    Nombre: 'Consulta de giros',
    Ubicacion: '22',
    Contenido: [
      {
        bigImageUrl: 'uploads/images/consulta-tus-giros.png',
        bigImageAlt: 'uploads/images/consulta-tus-giros.png',
        bigImageTitle: 'uploads/images/consulta-tus-giros.png',
        titleImageUrl: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        titleImageAlt: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        titleImageTitle: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        imageTitleMobile: 'uploads/images/consulta-tus-giros-titulo-movil.png',
        description: 'Confirma si tu giro ya fue reclamado o esta pendiente.'
      }
    ]
  }
  captchaValid = false;

  @ViewChild ('appFormCostTurn', {static: false}) formCostTurn: DynamicFormContainerComponent;
  @ViewChild ('appFormTurnStatus', {static: false}) formdTurnStatus: DynamicFormContainerComponent;
  @ViewChild('recaptcha', {static: false }) recaptchaElement: ElementRef;

  data: Array<{formCostTurn: any, formTurnStatus}> = [];

  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : '',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  }

  constructor(
    service: FieldsService,
    private apiService: ComponentService
  ) {
    this.data['formCostTurn'] = service.getQuestions(service.getCostTurn());
    this.data['formTurnStatus']= service.getQuestions(service.formTurnStatus());
  }

  ngOnInit() {
    if(this.contenido != undefined && this.contenido.Contenido.length != 0){
      this.checkGyres = this.contenido;
      this.addRecaptchaScript();
    }

  }


  onSubmitCostTurns(){
    if(this.formCostTurn.form.invalid){
      return false;
    }
    this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', 'Estamos consultando su informacion', );
    this.formCostTurn.onSubmit();
    const datos: any = this.formCostTurn.payLoad;
    this.apiService.getCalcularGiroNacional(datos.valorGiro).subscribe(
      (result: any) => {
        this.formCostTurn.form.controls.costo.setValue(formatNumber(result.tarifa.tarifaFija, 'en'));
        this.formCostTurn.form.controls.total.setValue(formatNumber(result.tarifa.valorTotalTransaccion,'en'));
        this.modal.hidde();
      },
      err => {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', err.message);
       }
    );

  }

  onSubmitTurnStatus() {
    if (this.formdTurnStatus.form.invalid || !this.captchaValid) {
      return false;
    }
    this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png','Espere mientras consultamos la información');
    this.formdTurnStatus.onSubmit();
    const datos: any = this.formdTurnStatus.payLoad;
    this.apiService.getValidarGiroNacional(datos.valor, datos.digito, datos.cboTipoDocumento,datos.NumDocumento).subscribe(
      (result: any) => {
        this.formdTurnStatus.form.controls.valor.setValue('');
        this.formdTurnStatus.form.controls.digito.setValue('');
        this.modal.show('assets/images/icon-modal-send.png', 'assets/images/title-modal-send.png', result.FCT.Estado);
      },
      err => {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', err.message);
      }
      );
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptcha();
    }

    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptcha(); return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptcha() {
    if(this.recaptchaElement){
      window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
        'sitekey' : '6LefLd0UAAAAAMKXEXDyCxitCvKT1LWkX01gC99E',
        'callback': (response) => {
          this.captchaValid = true;
        }
      });
    }
  }
}
