import { API_URI } from 'src/app/_services/apiUrl';
import { Directive, Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';
import { Colombia } from 'src/app/_classes/colombia';
import { FormatValidatorComponent } from '../format-validator/format-validator.component';
import { FORMERR } from 'dns';
import { FooterComponent } from '../footer/footer.component';
import { Page } from 'ngx-pagination/dist/pagination-controls.directive';

declare const $aivo: any;
@Component({
  selector: 'app-form-contact-adviser',
  templateUrl: './form-contact-adviser.component.html',
  styleUrls: ['./form-contact-adviser.component.scss']
})
export class FormContactAdviserComponent implements OnInit {
  
  chatbotImg: string = 'https://embed.agentbot.net/80a730fe763ac82254b8154a70f43e4c';
  @Input() page: Page;
  @Input() api_url: any;

  statusVerification: boolean = true;

  formContactAdviser = {
    Nombre: 'Radicar PQR',
    Ubicacion: '27',
    Contenido: [
      {
        Propiedad: 'header',
        Contenido: {
          icon: API_URI + 'uploads/images/Grupo 2028.png',
          title: 'Radicar PQR'
        }
      },
      {
        Propiedad: 'form',
        Contenido: {
          action: '/',
          Contenido: [
            {
              Propiedad: 'fields',
              Contenido: [
                /*{
                  Propiedad: 'selectproduc',
                  label: 'Producto de interés*',
                  control: 'tipo',
                    Contenido: [
                        {
                            option: 'Recaudo'
                        },
                        {
                            option: 'Giro Empresarial'
                        },                    
                        {
                            option: 'Pasarela de Pagos (Payty)'
                        },
                        
                        {
                            option: 'Punto de Atención/Red'
                        },
                        {
                          option: 'Producto Servientrega'
                        },
                        {
                            option: 'Otros'
                        }
                  ]
                },
                {
                    Propiedad: 'input-otro',
                    label: 'Cual?',
                    control: 'otro',
                    type: 'text',
                    placeholder: ''
                },
                {
                  Propiedad: 'input',
                  type: 'text',
                  label: 'Razón Social/Nombre*',
                  control: 'razonsocial',
                  placeholder: ''
                },*/
                {
                  Propiedad: 'selectdoc',
                  label: 'Tipo de Documento*',
                  control: 'tipodocumento',
                  Contenido: [
                    {
                      option: 'Cédula de ciudadanía',
                      value:"cc"
                    },
                    {
                      option: 'Cédula de extranjería',
                      value:"ce"
                    },
                    {
                      option: 'Pasaporte',
                      value:"pas"
                    },
                    {
                      option: 'NIT',
                      value:"nit"
                    }
                  ]
                },
                {
                  Propiedad: 'number',
                  type: 'number',
                  label: 'Número de Documento*',
                  placeholder: 'Sin puntos ni comas',
                  control: 'numerodocumento',
                },
                /*{
                  Propiedad: 'numberverificacion',
                  type: 'number',
                  label: 'Dígito de verificación',
                  placeholder: '',
                  control: 'digitoverficacion',
                },
                {
                  Propiedad: 'departamento',
                  label: 'Departamento*',
                  control: 'departamento',
                  Contenido: [
                    {
                      option: 'Municipio 1'
                    },
                    {
                      option: 'Municipio 2'
                    }
                  ]
                },
                {
                  Propiedad: 'municipio',
                  label: 'Municipio*',
                  control: 'municipio',
                  Contenido: [
                    {
                      option: 'Municipio 1'
                    },
                    {
                      option: 'Municipio 2'
                    }
                  ]
                },
                {
                  Propiedad: 'input',
                  type: 'text',
                  label: 'Dirección*',
                  placeholder: '',
                  control: 'direccion',
                },
                {
                  Propiedad: 'number',
                  type: 'number',
                  label: 'Número de Teléfono*',
                  placeholder: '',
                  control: 'telefono',
                },*/
                {
                  Propiedad: 'input',
                  type: 'text',
                  label: 'Nombre y Apellidos*',
                  placeholder: '',
                  control: 'contactonombre',
                  pattern: ''
                },
                {
                  Propiedad: 'input',
                  type: 'email',
                  label: 'Correo electrónico*',
                  placeholder: '',
                  control: 'contactocorreo'
                },
                {
                  Propiedad: 'number',
                  type: 'number',
                  label: 'Celular*',
                  placeholder: '',
                  control: 'contactotelefono',
                }
                /*{
                  Propiedad: 'input',
                  type: 'text',
                  label: 'Apellido de contacto*',
                  placeholder: '',
                  control: 'contactoapellido',
                  pattern: ''
                },
                
                {
                  Propiedad: 'input',
                  type: 'text',
                  label: 'Cargo del contacto*',
                  placeholder: '',
                  control: 'contactocargo',
                },
                {
                  Propiedad: 'select',
                  label: 'Tiempo de constitución*',
                  control: 'tiempoconstitucion',
                  Contenido: [
                    {
                      option: 'Menor a 6 meses'
                    },
                    {
                      option: 'Menor a 1 año'
                    },
                    {
                      option: 'Mayor a 1 año'
                    }
                  ]
                },
                {
                  Propiedad: 'number',
                  type: 'number',
                  label: 'Ventas mensuales*',
                  placeholder: '',
                  control: 'ventasmensuales',
                }*/
              ]
            }
          ],
          tratamientoDatos: 'Autorizo tratamiento de datos personales',
          policy: 'Conoce la política de datos aquí',
          button: 'Contactar'
        }
      }
    ]
  }
  pageForm: FormGroup;

  departamentos = Colombia;
  municipios: any = [];
  acitve : '';
  // convenience getter for easy access to form fields
  get f() {
    return this.pageForm.controls;
  }

  modal = {
    image : 'assets/images/icon-modal-send.png',
    title : 'assets/images/title-modal-send.png',
    text : 'El mensaje',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image, title, message){
      this.image = image;
      this.title = title;
      this.text = message;
      this.active = true;
    }
  }

  loading =  {
    image : 'assets/images/loading.gif',
    active : false,
    hidde() {
      this.active = false;
    },
    show(image){
      this.image = image;
      this.active = true;
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ComponentService
  ) { }

  ngOnInit() {

    this.pageForm = this.formBuilder.group({
      /*tipo: ['', Validators.required],
      razonsocial: ['', Validators.required],*/
      tipodocumento: ['', Validators.required],
      numerodocumento: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(15)
      ]],
      /*digitoverficacion: [''],
      tiempoconstitucion: ['', Validators.required],
      ventasmensuales: ['', Validators.required],
      direccion: ['', [
        Validators.required,
        Validators.maxLength(100)
      ]],
      municipio: ['', Validators.required],
      telefono: ['', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15)
      ]],*/
      contactotelefono: ['', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(15)
      ]],
      contactonombre: ['',
        Validators.compose([
          Validators.required,
          Validators.maxLength(250),
          Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/)
        ])
      ],
      /*contactoapellido: ['',
      Validators.compose([
        Validators.required,
        Validators.maxLength(250),
        Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/)
      ])
      ],
      contactocargo: ['', Validators.required],*/
      // contactotelefono: ['', Validators.required],
      contactocorreo: ['',  Validators.compose([
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ])
        ],
        //otro: ['',[]],
        tratamientoDatos: ['', [Validators.required, Validators.requiredTrue]]

    })
  }

    submit() {
    this.loading.show( 'assets/images/loading.gif');
    if(this.pageForm.invalid){
      /*if (this.pageForm.controls.otro.status === "INVALID" && this.pageForm.controls.tipo.value === 'Otros'){
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Campo (Cual) invalido');
        this.loading.hidde();
        
        return false;
      }
      else {*/
      let formValido = this.validarFormNuevamente(this.pageForm);

      if (!formValido) {
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Campos invalidos');
        this.loading.hidde();
  
        return false;
      }
      //}      
    }    
              
    this.apiService.postAsesoria(this.pageForm.value)
      .subscribe(
        response => {
          this.modal.show('assets/images/Grupo -6.png', 'assets/images/title-modal-send.png', 'Te llamaremos lo más pronto posible');
          this.loading.hidde();
          this.pageForm.reset();
        },
        err => {
          this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', err.message);
          this.loading.hidde();
        }
      );
  }

  validarFormNuevamente(pageForm: FormGroup): boolean {
    let formValido = false;

    /*formValido = pageForm.controls.contactoapellido.status === 'VALID';
    formValido = formValido && pageForm.controls.contactocargo.status === 'VALID';*/
    formValido = formValido && pageForm.controls.contactocorreo.status === 'VALID';
    formValido = formValido && pageForm.controls.contactonombre.status === 'VALID';
    formValido = formValido && pageForm.controls.contactotelefono.status === 'VALID';
    formValido = formValido && pageForm.controls.digitoverficacion.status === 'VALID';
    /*formValido = formValido && pageForm.controls.direccion.status === 'VALID';
    formValido = formValido && pageForm.controls.municipio.status === 'VALID';*/
    formValido = formValido && pageForm.controls.numerodocumento.status === 'VALID';
    /* formValido = formValido && pageForm.controls.razonsocial.status === 'VALID';
    formValido = formValido && pageForm.controls.telefono.status === 'VALID';*/
    formValido = formValido && pageForm.controls.tratamientoDatos.status === 'VALID';
    /*formValido = formValido && pageForm.controls.tiempoconstitucion.status === 'VALID';
    formValido = formValido && pageForm.controls.tipo.status === 'VALID';*/
    formValido = formValido && pageForm.controls.tipodocumento.status === 'VALID';
    //formValido = formValido && pageForm.controls.ventasmensuales.status === 'VALID';

    console.log('formValido', formValido);
    return formValido;
  }

  handleFileInput() {

  }

  opcionSeleccionado: string  = ''; // Iniciamos
  verSeleccion: string        = '';

  capturar($event) {
    this.verSeleccion = this.opcionSeleccionado;
    this.statusVerification = !this.statusVerification;
  }

  changeCity(evento){
    const departamento = this.departamentos.find(item => item.departamento == evento.target.value);
    this.municipios = departamento.ciudades;
  }
  
  abrirChat(){
    $aivo!.chat.open();
  }
}
