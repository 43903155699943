import { Component, OnInit, Input  } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-search-agreements',
  templateUrl: './search-agreements.component.html',
  styleUrls: ['./search-agreements.component.scss'],
  providers: [ComponentService]
})
export class SearchAgreementsComponent implements OnInit  {
    public p: number
    @Input() contenido:any;
    @Input() api_url:any;
    
    agreements = {
      Nombre: 'Covenios',
      Ubicacion: '25',
      Contenido: [
        {
            title: 'Busca la empresa a la cuál debes realizar tu pago:',
            proyecto: 'Empresa',
            convenio: 'Convenio',
            numero: 'Número de Convenio',
            captura: 'Dato Captura Manual',
            vencido: 'Recibe Vencido',
            pago: 'Recibe Pagos Parciales',
            form: {
              field: 'Nombre del Convenio',
              fieldNumber: 'Número de Convenio'
            }
          }
        ]
      }
    error= false;
    listaFiltrada:any[] = []
    pageForm: FormGroup;
    modal = {
      image : 'assets/images/icon-modal-send.png',
      title : 'assets/images/title-modal-send.png',
      text : 'El mensaje',
      active : false,
      hidde() {
        this.active = false;
      },
      show(image, title, message){
        this.image = image;
        this.title = title;
        this.text = message;
        this.active = true;
      }
    }

    loading =  {
      image : 'assets/images/loading.gif',
      active : false,
      hidde() {
        this.active = false;
      },
      show(image){
        this.image = image;
        this.active = true;
      }
    };

    constructor(
      private formBuilder: FormBuilder,
      private apiService: ComponentService,
    ) { }
    

    ngOnInit() {
      if(this.contenido != undefined && this.contenido.Contenido.length != 0){
        if(this.contenido.Contenido[0].form == null){
          this.contenido.Contenido[0].form=   this.agreements.Contenido[0].form;
        }
        this.agreements = this.contenido;
      }
      this.pageForm = this.formBuilder.group({
        nombre : ['',
          [
          Validators.pattern(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/),
          Validators.minLength(4),
          Validators.maxLength(250)]
        ],
        numero : ['', [Validators.max(999999999999999)]],
      });

     
    }
    
    submit() {
      this.loading.show( 'assets/images/loading.gif');
      this.listaFiltrada = []
      this.p=1;
      if(this.pageForm.invalid){
          this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Campos inválidos');
          this.loading.hidde();
          this.error=false;
          return false;
      }
      if((this.pageForm.controls.nombre.value == null || this.pageForm.controls.nombre.value =='') && 
      (this.pageForm.controls.numero.value == null || this.pageForm.controls.numero.value =='')){
        this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Campos inválidos');
        this.loading.hidde();
        this.error=true;
        return false;
      }
      this.apiService.getConvenios(this.pageForm.controls.numero.value,this.pageForm.controls.nombre.value)
      .subscribe(
        response => {
            if((response as any[]).length > 0){
              this.listaFiltrada=(response as any[]);
            }
            else{
              this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'No se encontró información');
              
            }
            this.pageForm.reset();
            this.error=false;
            this.loading.hidde();
        },
        err =>{
          this.modal.show('assets/images/icon-modal-error.png',  'assets/images/title-modal-error.png', 'Intenta de nuevo mas tarde');
          this.error=false;
          this.loading.hidde();
        }
        );
    }    
    
  }
