import { SITE_KEY } from './../_services/apiUrl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StepsGalleryComponent } from './steps-gallery/steps-gallery.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { HorizontalActionComponent } from './horizontal-action/horizontal-action.component';
import { InformativeContentTypeOneComponent } from './informative-content-type-one/informative-content-type-one.component';
import { InformativeContentTypeTwoComponent } from './informative-content-type-two/informative-content-type-two.component';
import { SliderCardsComponent } from './slider-cards/slider-cards.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SliderFindUsIconsComponent } from './slider-find-us-icons/slider-find-us-icons.component';
import { ProfitabilityComponent } from './profitability/profitability.component';
import { CheckGyresComponent } from './check-gyres/check-gyres.component';
import { CalculateGyreComponent } from './calculate-gyre/calculate-gyre.component';
import { BannerMainComponent } from './banner-main/banner-main.component';
import { CmsProductsAndServicesComponent } from './cms-products-and-services/cms-products-and-services.component';
import { CmsPaytyComponent } from './cms-payty/cms-payty.component';
import { TabsSlidersComponent } from './tabs-sliders/tabs-sliders.component';
import { TabsComponent } from './tabs-sliders/tabscomponent/tabs.component';
import { TabComponent } from './tabs-sliders/tabscomponent/tab.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ModalCarouselComponent } from './modal-carousel/modal-carousel.component';
import { TimeLineComponent } from './time-line/time-line.component';
import { MapComponent } from './map/map.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { SearchAgreementsComponent } from './search-agreements/search-agreements.component';
import { FormatValidatorComponent } from './format-validator/format-validator.component';
import { DynamicFormComponent } from '../_helpers/dynamic_form/subcomponents/dynamic-form/dynamic-form.component';
import { DynamicFormContainerComponent } from '../_helpers/dynamic_form/subcomponents/dynamic-form-container/dynamic-form-container.component';
import { FormDynamicComponent } from './form-dynamic/form-dynamic.component';
import { AttentionClientComponent } from './attention-client/attention-client.component';
import { AttentionSpecializedComponent } from './attention-specialized/attention-specialized.component';
import { SafetyRecommendationsComponent } from './safety-recommendations/safety-recommendations.component';
import { NormativityComponent } from './normativity/normativity.component';
import { FrequentQuestionsComponent } from './frequent-questions/frequent-questions.component';
import { InternalPortalsComponent } from './internal-portals/internal-portals.component';
import { CollectionsFacilitatePaymentsComponent } from './collections-facilitate-payments/collections-facilitate-payments.component';
import { HistoricalReviewComponent } from './historical-review/historical-review.component';
import { SustainabilityComponent } from './sustainability/sustainability.component';
import { RegionalContactNumbersComponent } from './regional-contact-numbers/regional-contact-numbers.component';
import { InformationMtcnComponent } from './information-mtcn/information-mtcn.component';
import { AboutUsWeAreEfectyComponent } from './about-us-we-are-efecty/about-us-we-are-efecty.component';
import { InternationalGyresComponent } from './international-gyres/international-gyres.component';
import { InternationalConditionsChargeComponent } from './international-conditions-charge/international-conditions-charge.component';
import { InternationalConditionsSendComponent } from './international-conditions-send/international-conditions-send.component';
import { InternationalCalculateGyreComponent } from './international-calculate-gyre/international-calculate-gyre.component';
import { InternationalCheckGyreComponent } from './international-check-gyre/international-check-gyre.component';
import { AboutUsInnovationComponent } from './about-us-innovation/about-us-innovation.component';
import { AboutUsOurPurposeComponent } from './about-us-our-purpose/about-us-our-purpose.component';
import { WorkWithUsWeAreCharacterizedByComponent } from './work-with-us-we-are-characterized-by/work-with-us-we-are-characterized-by.component';
import { WorkWithUsOurValuesComponent } from './work-with-us-our-values/work-with-us-our-values.component';
import { WorkWithUsWeLookForTalentComponent } from './work-with-us-we-look-for-talent/work-with-us-we-look-for-talent.component';
import { DynamicBannerComponent } from '../_helpers/dynamic_banner/subcomponents/dynamic-banner/dynamic-banner.component';
import { WorkWithUsBenefitsAndTrainingComponent } from './work-with-us-benefits-and-training/work-with-us-benefits-and-training.component';
import { SidebarTabsComponent } from './sidebar-tabs/sidebar-tabs.component';
import { FooterComponent } from './footer/footer.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { PricingComponent } from './pricing/pricing.component';
import { WorkWithUsPerformanceComponent } from './work-with-us-performance/work-with-us-performance.component';
import { ThreeColumnsComponent } from './three-columns/three-columns.component';
import { ImageTitleComponent } from './image-title/image-title.component';
import { BannerMainSimpleComponent } from './banner-main-simple/banner-main-simple.component';
import { DescriptionListComponent } from './description-list/description-list.component';
import { ArticleCardComponent } from './article-card/article-card.component';
import { PromoCardComponent } from './promo-card/promo-card.component';
import { PqrComponent } from './pqr/pqr.component';
import { FormSubmitPqrComponent } from './form-submit-pqr/form-submit-pqr.component';
import { SingleBlogComponent } from './single-blog/single-blog.component';
import { FormInformationGyreComponent } from './form-information-gyre/form-information-gyre.component';
import { FormAdvisoryComponent } from './form-advisory/form-advisory.component';
import { FormAdvisoryTypeTwoComponent } from './form-advisory-type-two/form-advisory-type-two.component';
import { FormContactAdvisoryComponent } from './form-contact-advisory/form-contact-advisory.component';
import { ThreeColumnGyreComponent } from './three-column-gyre/three-column-gyre.component';
import { FormContactAdviserComponent } from './form-contact-adviser/form-contact-adviser.component';
import { ErrorComponent } from './error/error.component';
import { ValidatorMainComponent } from './validator-main/validator-main.component';
import { ComponentService } from '../_services/templates.service';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { FormPuntoPqrRadicarComponent } from './form-punto-pqr-radicar/form-punto-pqr-radicar.component';
import { FormPuntoPqrConsultarComponent } from './form-punto-pqr-consultar/form-punto-pqr-consultar.component';
import { SearchComponent } from './search/search.component';
import { FormCostTurnComponent } from './form-cost-turn/form-cost-turn.component';
import { DndDirective } from '../_helpers/dnd.directive';
import { OnlynumbersDirective } from '../_helpers/onlynumbers.directive';
import { CmsProductsAndServicesCompComponent } from './cms-products-and-services-comp/cms-products-and-services-comp.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RecaptchaModule,RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

@NgModule({
  declarations: [
    NavbarComponent,
    StepsGalleryComponent,
    PageTitleComponent,
    HorizontalActionComponent,
    InformativeContentTypeOneComponent,
    InformativeContentTypeTwoComponent,
    SliderCardsComponent,
    SliderFindUsIconsComponent,
    ProfitabilityComponent,
    CheckGyresComponent,
    CalculateGyreComponent,
    BannerMainComponent,
    CmsProductsAndServicesComponent,
    CmsPaytyComponent,
    TabsSlidersComponent,
    TabsComponent,
    TabComponent,
   // BreadcrumbComponent,
    ModalCarouselComponent,
    TimeLineComponent,
    ModalCarouselComponent,
    FormatValidatorComponent,
    MapComponent,
    FormDynamicComponent,
    AgreementsComponent,
    SearchAgreementsComponent,
    DynamicFormComponent,
    DynamicFormContainerComponent,
    AttentionClientComponent,
    AttentionSpecializedComponent,
    SafetyRecommendationsComponent,
    NormativityComponent,
    FrequentQuestionsComponent,
    InternalPortalsComponent,
    CollectionsFacilitatePaymentsComponent,
    HistoricalReviewComponent,
    SustainabilityComponent,
    RegionalContactNumbersComponent,
    InformationMtcnComponent,
    AboutUsWeAreEfectyComponent,
    InternationalGyresComponent,
    InternationalConditionsChargeComponent,
    InternationalConditionsSendComponent,
    InternationalCalculateGyreComponent,
    InternationalCheckGyreComponent,
    AboutUsInnovationComponent,
    AboutUsOurPurposeComponent,
    WorkWithUsWeAreCharacterizedByComponent,
    WorkWithUsOurValuesComponent,
    WorkWithUsWeLookForTalentComponent,
    DynamicBannerComponent,
    WorkWithUsBenefitsAndTrainingComponent,
    SidebarTabsComponent,
    FooterComponent,
    CopyrightComponent,
    PricingComponent,
    WorkWithUsPerformanceComponent,
    ThreeColumnsComponent,
    ImageTitleComponent,
    BannerMainSimpleComponent,
    DescriptionListComponent,
    ArticleCardComponent,
    PromoCardComponent,
    PqrComponent,
    FormSubmitPqrComponent,
    SingleBlogComponent,
    FormInformationGyreComponent,
    FormAdvisoryComponent,
    FormAdvisoryTypeTwoComponent,
    FormContactAdvisoryComponent,
    ThreeColumnGyreComponent,
    FormContactAdviserComponent,
    ErrorComponent,
    ValidatorMainComponent,
    ModalAlertComponent,
    FormPuntoPqrRadicarComponent,
    FormPuntoPqrConsultarComponent,
    SearchComponent,
    FormCostTurnComponent,
    DndDirective,
    OnlynumbersDirective,
    CmsProductsAndServicesCompComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    NgbModule,
    NgxPaginationModule,
    RecaptchaModule
  ],
  exports: [
    NavbarComponent,
    StepsGalleryComponent,
    PageTitleComponent,
    HorizontalActionComponent,
    InformativeContentTypeOneComponent,
    InformativeContentTypeTwoComponent,
    SliderCardsComponent,
    SliderFindUsIconsComponent,
    ProfitabilityComponent,
    CheckGyresComponent,
    CalculateGyreComponent,
    BannerMainComponent,
    CmsProductsAndServicesComponent,
    CmsPaytyComponent,
    TabsSlidersComponent,
    TabsComponent,
    TabComponent,
    // BreadcrumbComponent,
    ModalCarouselComponent,
    TimeLineComponent,
    ModalCarouselComponent,
    FormatValidatorComponent,
    MapComponent,
    FormDynamicComponent,
    AgreementsComponent,
    SearchAgreementsComponent,
    DynamicFormComponent,
    AttentionClientComponent,
    AttentionSpecializedComponent,
    SafetyRecommendationsComponent,
    NormativityComponent,
    FrequentQuestionsComponent,
    InternalPortalsComponent,
    CollectionsFacilitatePaymentsComponent,
    HistoricalReviewComponent,
    SustainabilityComponent,
    RegionalContactNumbersComponent,
    InformationMtcnComponent,
    AboutUsWeAreEfectyComponent,
    InternationalGyresComponent,
    InternationalConditionsChargeComponent,
    InternationalConditionsSendComponent,
    InternationalCalculateGyreComponent,
    InternationalCheckGyreComponent,
    AboutUsInnovationComponent,
    AboutUsOurPurposeComponent,
    WorkWithUsWeAreCharacterizedByComponent,
    WorkWithUsOurValuesComponent,
    WorkWithUsWeLookForTalentComponent,
    DynamicBannerComponent,
    WorkWithUsBenefitsAndTrainingComponent,
    SidebarTabsComponent,
    FooterComponent,
    CopyrightComponent,
    PricingComponent,
    WorkWithUsPerformanceComponent,
    ThreeColumnsComponent,
    ImageTitleComponent,
    BannerMainSimpleComponent,
    DescriptionListComponent,
    ArticleCardComponent,
    PromoCardComponent,
    PqrComponent,
    FormSubmitPqrComponent,
    SingleBlogComponent,
    FormInformationGyreComponent,
    FormAdvisoryComponent,
    FormAdvisoryTypeTwoComponent,
    FormContactAdvisoryComponent,
    ThreeColumnGyreComponent,
    FormContactAdviserComponent,
    ErrorComponent,
    ValidatorMainComponent,
    FormPuntoPqrRadicarComponent,
    FormPuntoPqrConsultarComponent,
    SearchComponent,
    FormCostTurnComponent,
    CmsProductsAndServicesCompComponent
  ],
  providers: [
    ComponentService,
    {
      provide:RECAPTCHA_SETTINGS,
      useValue:{siteKey:SITE_KEY}as RecaptchaSettings,
    },
  ],
})
export class ComponentsModule {}
