import { API_URI } from 'src/app/_services/apiUrl';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { ComponentService } from '../../_services/templates.service';

@Component({
    selector: 'app-form-punto-pqr-consultar',
    templateUrl: './form-punto-pqr-consultar.component.html',
    styleUrls: ['./form-punto-pqr-consultar.component.scss']
})
export class FormPuntoPqrConsultarComponent implements OnInit {

    pqr = {
        Nombre: 'Consultar PQR',
        Ubicacion: '26',
        Contenido: [
            {
                Propiedad: 'header',
                Contenido: {
                    icon: API_URI + 'uploads/images/Grupo 2017.png',
                    title: 'Consultar PQR',
                    subtitle: 'Seleccione método de consulta:',
                    description: 'Para realizar la consulta es importante que tengas disponible el número de radicación que se informó en el momento de realizar tu solicitud o Número de CUN enviado por medio del correo electrónico de confirmación.'
                }
            },
            {
                Propiedad: 'form',
                Contenido: [
                    {
                        Propiedad: 'form1',
                        Contenido: [
                            {
                                Propiedad: 'row1',
                                Contenido: [
                                    {
                                        Propiedad: 'input1',
                                        name: 'CodigoPunto',
                                        label: 'Código Punto de Atención:'
                                    },
                                    {
                                        Propiedad: 'input2',
                                        name: 'NumeroCaso',
                                        label: 'Número de caso:',
                                        placeholder: 'Escribe tu número sin puntos ni comas'
                                    }
                                ]
                            },
                            {
                                Propiedad: 'row2',
                                Contenido: [
                                    {
                                        Propiedad: 'button'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                Propiedad: 'table',
                Contenido: [
                    {
                        title: 'Punto de atención al público',
                        description: ''
                    },
                    {
                        title: 'Número de Radicado',
                        description: ''
                    },
                    {
                        title: 'Tipo de solicitud',
                        description: ''
                    },
                    {
                        title: 'Fecha de creación',
                        description: ''
                    },
                    {
                        title: 'Fecha estimada de solución',
                        description: ''
                    },
                    {
                        title: 'Estado trámite',
                        description: ''
                    },
                    {
                        title: 'Fecha de respuesta',
                        description: ''
                    },
                    {
                        title: 'Descarga respuesta',
                        description: ''
                    },
                ]
            }
        ]
    };

    modal = {
        image: 'assets/images/icon-modal-send.png',
        title: 'assets/images/title-modal-send.png',
        text: 'El mensaje',
        active: false,
        hidde() {
            this.active = false;
        },
        show(image, title, message) {
            this.title = title;
            this.text = message;
            this.image = image;
            this.active = true;
        }
    };

    loading = {
        image: 'assets/images/loading.gif',
        active: false,
        hidde() {
            this.active = false;
        },
        show(image) {
            this.image = image;
            this.active = true;
        }
    };

    pageForm: FormGroup;
    listado: any;

    constructor(
        private formBuilder: FormBuilder,
        private apiService: ComponentService,

    ) { }

    ngOnInit() {
        this.pageForm = this.formBuilder.group({
            CodigoPunto: ['', Validators.required],
            NumeroCaso: ['', Validators.required],
        });
    }

    async submit() {
        this.loading.show('assets/images/loading.gif');
        if (this.pageForm.invalid) {
            this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Todos los campos son obligatorios, ' + this.getFormValidationErrors());
            this.loading.hidde();
            return;
        }
        const datos = this.pageForm.value;
        await this.apiService.getJSON();
        this.apiService.postConsultaPQRPunto(datos.CodigoPunto, datos.NumeroCaso).subscribe(
            (result: any) => {
                if (result.length == 0) {
                    this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'No se encontraron resultados');
                }
                this.listado = result;
                this.loading.hidde();
            },
            err => {
                this.modal.show('assets/images/icon-modal-error.png', 'assets/images/title-modal-error.png', 'Ocurrio un error, intenta de nuevo mas tarde');
                this.loading.hidde();
            }
        );
    }

    getFormValidationErrors() {
        let textErrors = '';
        Object.keys(this.pageForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.pageForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    textErrors += 'Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError];
                });
            }
        });
        return textErrors;
    }
    convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    async descargar(response) {
        await this.apiService.getJSON();
        this.apiService.postConsultaPQRArchivo(response.GUID, response.NumeroRadicado).subscribe(
            (result: any) => {
                var blobR = this.convertBase64ToBlobData(result.ContenidoArchivo, result.MimeType, result.ContenidoArchivo.length);
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveBlob(blobR, result.NombreArchivo);
                }
                else {
                    var a = window.document.createElement("a");
                    let blob = new Blob([blobR], { type: result.MimeType });
                    a.href = URL.createObjectURL(blob);
                    a.download = result.NombreArchivo;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            },
            err => {
                this.modal.show('assets/images/icon-modal-error.png',
                    'assets/images/title-modal-error.png',
                    'Su solictud aún no tiene documentos disponibles para descargar');
                this.loading.hidde();
            }
        );
    }

}
