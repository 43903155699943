import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'encrypData'
})
@Injectable({
  providedIn: 'root'
})

export class EncrypDataPipe implements PipeTransform {

  private mask : string = "*"
  
  transform(value : string): string {
    if(value === "" || value === null){
      
      return value;
    }

    if(value.length === 10){
      const data = value.substr(6,4);
      
      return this.returnMask(6) + data;
    }
    
    if(value.indexOf('@') >= 0){
      const objValue = value.split('@');
    return objValue[0].substr(0,2) + this.returnMask(objValue[0].length -2) +'@'+ objValue[1].toString();
    }else{
      return this.returnMask(value.length);
    }
  }

  private returnMask(count : number){
    return this.mask.repeat(count);
  }
}
