import { Injectable } from '@angular/core';

import { FieldsBase } from '../class/fields-base';
import { FieldsText } from '../class/fields-text';
import { FieldsArea } from '../class/fields-area';
import { FieldsSelected } from '../class/fields-selected';
import { element } from 'protractor';
import { isContext } from 'vm';
import { Validators } from '@angular/forms';


@Injectable()
export class FieldsService {

    public dataServe: Array<{}> = []
    public data: FieldsBase<any>[] = []

    constructor(){
    }

    getHttpConfigFormDynamic(){
        return this.dataServe = [
            {
                name:'',
                controlType: 'selected',
                key: 'DinamicSelected1',
                label: 'Bravery Rating',
                options: [
                  {key: 'solid',  value: 'Solid'},
                  {key: 'great',  value: 'Great'},
                  {key: 'good',   value: 'Good'},
                  {key: 'unproven', value: 'Unproven'}
                ],
                placeholder: 'cedula',
                order: 2
            },
            {
                name:'',
                controlType: 'text',
                key: 'DinamicText1',
                label: 'cuantos establecimientos tiene mercadona abiertos al publico tienes',
                placeholder: 'sin puntos ni comas',
                order: 1
            },
            {
                name:'',
                controlType: 'textarea',
                key: 'DinamicTextA1',
                label: 'Bravery mensaje',
                placeholder: '',
                order: 3
            }
        ]
    }

    getCostTurn() {
        return [
            {
                name:'',
                controlType: 'text',
                type: 'number',
                key: 'valorGiro',
                label: '¿Cuánto deseas enviar?',
                placeholder: '$ Valor del Giro',
                styleCms: true,
                hasIcon: true,
                order: 1,
                validators : [
                  Validators.required,
                  Validators.max(3000000),
                  Validators.min(5000)
                ],
                errormessages:{
                  required: 'Este campo es obligatorio',
                  min: 'No puedes enviar menos de $5.000',
                  max: 'No puedes enviar mas de $3.000.000'
                }
            },
            {
                name:'',
                controlType: 'text',
                key: 'costo',
                label: 'Costo del envío',
                value: '0',
                styleCms: true,
                hasIcon: true,
                icon: '$',
                readOnly: true,
                order: 2,
                emitEvent: false
            },
            {
                name:'',
                controlType: 'text',
                key: 'total',
                label: 'Total a pagar',
                value: '0',
                styleCms: true,
                hasIcon: true,
                icon: '$',
                readOnly: true,
                order: 3,
                emitEvent: false
            }
        ]
    }


     formTurnStatus() {
        return [
            {
                name: '',
                controlType: 'text',
                key: 'valor',
                label: 'Número de Giro (Prueba de Admisión)',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                order: 1,
                validators: [
                    Validators.required,
                ],
                errormessages: {
                    required: 'Este campo es obligatorio',
                }
            },
            {
                name: '',
                controlType: 'text',
                key: 'digito',
                label: 'Dígito de verificación',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                order: 2,
                validators: [
                    Validators.required,
                ],
                errormessages: {
                    required: 'Este campo es obligatorio',
                }
            },
            {
                name: '',
                controlType: 'selected',
                key: 'cboTipoDocumento',
                label: 'Tipo de documento',
                placeholder: 'Seleccione',
                optionsArray:[
                    { key: 'CC ',  value: 'CEDULA DE CIUDADANIA'},
                    { key: 'CE ',  value: 'CEDULA DE EXTRANJERIA'},
                    { key: 'CM ',  value: 'CEDULA MILITAR Y/O POLICIAL'},
                    { key: 'CS ',  value: 'CONTRASEÑA CEDULA DE CIUDADANIA'},
                    { key: 'CV ',  value: 'CEDULA VENEZOLANA'},
                    { key: 'LC ',  value: 'LICENCIA DE CONDUCIR'},
                    { key: 'LM ',  value: 'LIBRETA MILITAR'},
                    { key: 'NIT',  value: 'NIT'},
                    { key: 'PAS',  value: 'PASAPORTE'},
                    { key: 'PJ ',  value: 'PASADO JUDICIAL'},
                    { key: 'TI ',  value: 'TARJETA DE IDENTIDAD'},
                    
                ],
                styleCms: true,
                hasIcon: true,
                order: 3,
                validators: [
                    Validators.required,
                ],
                errormessages: {
                    required: 'Este campo es obligatorio',
                }
            },
            {
                name: '',
                controlType: 'text',
                key: 'NumDocumento',
                label: 'Numero de documento*',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                hasIcon: true,
                order: 4,
                validators: [
                    Validators.required,
                ],
                errormessages: {
                    required: 'Este campo es obligatorio',
                }
            }
        ]
    }

    getInternationalCheckGyreForm(){
        return [
            {
                name:'',
                controlType: 'text',
                key: 'checkInternationalForm2',
                label: 'Tipo de documento',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                hasIcon: true,
                order: 1
            },
            {
                name:'',
                controlType: 'text',
                key: 'checkInternationalForm3',
                label: 'País de Origen',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                hasIcon: true,
                order: 2
            },
            {
                name:'',
                controlType: 'text',
                key: 'checkInternationalForm2',
                label: 'Numero de documento*',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                hasIcon: true,
                order: 3
            },
            {
                name:'',
                controlType: 'text',
                key: 'checkInternationalForm4',
                label: 'Código de giro*',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                hasIcon: true,
                order: 4
            },
            {
                name:'',
                controlType: 'text',
                key: 'checkInternationalForm5',
                label: 'Moneda de giro*',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                hasIcon: true,
                order: 5
            },
            {
                name:'',
                controlType: 'text',
                key: 'checkInternationalForm6',
                label: 'Monto de Giro*',
                placeholder: 'Sin puntos ni comas',
                styleCms: true,
                hasIcon: true,
                order: 6
            }
        ]
    }
    getInternationalGyreForm(){
        return [
            {
                name:'',
                controlType: 'text',
                key: 'internationalCostTurnsText1',
                label: '¿Cuánto deseas enviar?',
                placeholder: '$ Valor del Giro',
                styleCms: true,
                hasIcon: true,
                order: 1
            },
            {
                name:'',
                controlType: 'text',
                key: 'internationalCostTurnsText2',
                label: '',
                placeholder: 'País/Moneda',
                styleCms: true,
                hasIcon: true,
                order: 2
            },
            {
                name:'',
                controlType: 'text',
                key: 'internationalCostTurnsText3',
                label: 'Costo del envío en pesos colombianos',
                value: '0',
                styleCms: true,
                hasIcon: true,
                icon: "$",
                readOnly: true,
                order: 3
            },
            {
                name:'',
                controlType: 'text',
                key: 'internationalCostTurnsText4',
                label: 'Costo del envío en moneda destino',
                value: '0',
                styleCms: true,
                hasIcon: true,
                icon: "$",
                readOnly: true,
                order: 3
            },
            {
                name:'',
                controlType: 'text',
                key: 'internationalCostTurnsText5',
                label: 'Total a pagar',
                value: '0',
                styleCms: true,
                hasIcon: true,
                icon: '$',
                readOnly: true,
                order: 4
            }
        ]
    }


    getQuestions(data) {
      this.data = [];
      data.forEach(item => {
          this.data.push(this.createdObject(item));
      });
      return this.data.sort((a, b) => a.order - b.order);
    }

    createdObject(objecto): any {
        switch (objecto.controlType) {
            case 'text':
                return new FieldsText(objecto);
            case 'selected':
                return  new FieldsSelected(objecto);
            case 'textarea':
                return  new FieldsArea(objecto);

        }
    }
}
